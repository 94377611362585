import Head from "next/head"
import {
  ConnectCompanySection,
  Document,
  FooterSection,
  FunctionIntroduction,
  GrowthSection,
  IssuesSection,
  NewBannerSection,
  NewHeader,
  RequestBanner,
  SalesAgentSection,
  Table,
} from "../components"
import { theme } from "@project/shared"

const Agents = () => {
  return (
    <>
      <Head>
        <title>Sales Agent</title>
        <link rel="icon" href="/assets/favicon.ico" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;500;700&display=swap"
          rel="stylesheet"
        />
        <meta
          name="google-site-verification"
          content="wK3EERO-ytBp8SunDuFwWnWUVpHJUkuBAVusXc5TJc0"
        />
        <meta
          name="description"
          content="「Sales Agent」が貴社の求人開拓営業業務を代行。 スピーディーな求人開拓が可能です。"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Sales Agent" />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_CONSUMER_HOST}/assets/images/logo.jpg`}
        />
        <meta
          property="og:url"
          content={process.env.NEXT_PUBLIC_CONSUMER_HOST}
          key="urlshare"
        />
        <meta property="og:type" content="website" key="typeshare" />
        <meta property="og:title" content="Sales Agent" key="titleshare" />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_CONSUMER_HOST}/assets/images/logo.jpg`}
          key="imageshare"
        />
        <meta property="og:image:width" content="500" key="imgwidthshare" />
        <meta property="og:image:height" content="250" key="imgheightshare" />
      </Head>
      <div>
        <NewHeader />
        <NewBannerSection agent={false} />
        <ConnectCompanySection />
        <IssuesSection />
        <GrowthSection />
        <SalesAgentSection />
        <RequestBanner />
        <FunctionIntroduction />
        <Table />
        <Document bg_color={theme.cardGreyBg} content_bg_color={theme.base} />
        <FooterSection />
      </div>
    </>
  )
}

export default Agents
